import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useIsAuthenticated } from "@azure/msal-react";
import { SignInButton } from "./SignInButton";
import { SignOutButton } from "./SignOutButton";
import "../index.css";

export const PageLayout = (props) => {
  const isAuthenticated = useIsAuthenticated();

  return (
    <>
      <Navbar style={{ backgroundColor: "#4287f5", boxShadow: "0 3px 6px rgba(0,0,0,0.16)" }} expand="lg">
        <Navbar.Brand href="/">Leap IT</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
                <NavDropdown title="Services" id="basic-nav-dropdown">
                    <NavDropdown.Item href="#action/3.1">Service 1</NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.2">Service 2</NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.3">Service 3</NavDropdown.Item>
                </NavDropdown>
                <Nav.Link href="#about">About</Nav.Link>
                <Nav.Link href="#contact">Contact</Nav.Link>
            </Nav>
          <Nav className="ml-auto">
            {isAuthenticated ? <SignOutButton /> : <SignInButton />}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <div className="container-app">
        <h5>
          <center>Welcome to the Leap IT Test Website</center>
        </h5>
        <br />
        <br />
        <div>
          {React.Children.map(props.children, child => (
            <div className="props-item">{child}</div>
          ))}
        </div>
      </div>
    </>
  );
};
