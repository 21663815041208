export const msalConfig = {
    auth: {
      clientId: "eccaf70a-c1eb-4b3c-bdad-1daf8a7c8ad6",
      authority: "https://login.microsoftonline.com/b505e40c-a82c-4d4a-9456-e14cf816ce49", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
      redirectUri: "https://web.leapit.us",
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
  };
  
  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest = {
   scopes: ["User.Read"]
  };
  
  // Add the endpoints here for Microsoft Graph API services you'd like to use.
  export const graphConfig = {
      graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
      graphMeGroupsEndpoint: "https://graph.microsoft.com/v1.0/me/transitiveMemberOf/microsoft.graph.group?$count=true"
  };
